import { Box } from 'components/Box';
import { OuterContainer, Body } from './AccentCard.styled';

import { TextContainer } from './TextContainer.styled';
import type { Highlight } from 'styled/types';
import type { ReactNode, FC } from 'react';

interface AccentCard {
  color?: Highlight;
  title: string;
  subTitle?: ReactNode;
  text?: ReactNode;
  button?: ReactNode;
  details?: ReactNode;
  footer?: string;
}

export type AccentCardsProps = AccentCard[];

export const AccentCard: FC<AccentCard> = ({
  color,
  title,
  subTitle,
  text,
  button,
  details,
  footer,
}) => (
  <OuterContainer background={color} aria-label={`${title}-card`}>
    <Box margin={8} marginTop={4} width="unset">
      <h2>{title}</h2>
      {subTitle && <div>{subTitle}</div>}
    </Box>

    <Box background={color}>
      <Body>
        {text && <div>{text}</div>}
        {details && <div>{details}</div>} {button && <Box paddingTop={4}>{button}</Box>}
      </Body>
    </Box>

    {footer && (
      <Box background="cream" padding={6}>
        <TextContainer>
          <strong>{footer}</strong>
        </TextContainer>
      </Box>
    )}
  </OuterContainer>
);
