import Pluralize from 'pluralize';

import { Feature, FeaturesContainer } from './Features.styled';

import type { FC } from 'react';
import type { PlanData } from 'page-data/pricing/types';

export interface FeaturesProps
  extends Pick<
    PlanData,
    'portfolios' | 'customGroups' | 'holdings' | 'reportsText' | 'supportLevel'
  > {}

export const Features: FC<FeaturesProps> = ({
  portfolios,
  holdings,
  customGroups,
  reportsText,
  supportLevel,
}) => (
  <FeaturesContainer>
    <Feature>
      {portfolios} {Pluralize('Portfolio', portfolios)}
    </Feature>
    <Feature>
      {holdings} {holdings === 'Unlimited' ? 'Holdings' : Pluralize('Holdings', holdings)}
    </Feature>
    <Feature>
      {customGroups} {Pluralize('Custom group', customGroups)}
    </Feature>
    <Feature>{reportsText} reporting</Feature>
    <Feature>{supportLevel} support</Feature>
  </FeaturesContainer>
);
