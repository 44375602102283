import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { getDimension } from 'styled/helpers/getDimension';
import type { Highlight } from 'styled/types';

export const Body = styled.div`
  margin: ${getDimension('size6')};

  // space out elements
  > * + * {
    margin-top: ${getDimension('size4')};
  }

  p {
    font-size: ${props => props.theme.fontSize.xxs};
  }
`;

export const OuterContainer = styled.div<{
  background?: Highlight;
}>`
  ${({ theme, background }) => css`
    width: 270px;
    display: flex;
    flex-direction: column;

    box-shadow: ${theme.boxShadow};
    border-radius: ${theme.borderRadius};

    margin: ${getDimension('size1')};
    background-color: ${theme.colors.white};

    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;

    &::before {
      content: '';
      width: 100%;
      height: ${getDimension('size4')};
      top: 0;

      background-color: ${theme.colors.white};

      ${background &&
      css`
        background-color: ${darken(0.15, theme.colors[background])};
      `}
    }
  `}

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 120px;
  }
`;
