import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';

import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { getAnnualPrice } from 'helpers/getAnnualPrice';

import { pricingPlans } from 'page-data/pricing';

import { AccentCard, AccentCardsContainer } from 'components/AccentCards';

import { Box } from 'components/Box';
import { PriceDetail } from './PriceDetail';
import { Features } from './Features';
import { StyledSignupLink, FooterNote } from './PricingDetail.styled';

import type { FC } from 'react';
import type { LocalePlan, Plan } from 'page-data/pricing';

const planOptions: Plan[] = ['Free', 'Starter', 'Investor', 'Expert'];

export const PricingCards: FC<{ pricingDiscountPercentage?: number }> = ({
  pricingDiscountPercentage,
}) => {
  const {
    priceIncludesTax,
    translations: { currencyCode, currencySymbol },
  } = useContentfulLocale();

  const {
    currentLocale: { id },
  } = useLocalizationContext();

  // Get localized pricing plans
  const localizedPlans: LocalePlan = pricingPlans[id];

  const taxDisclaimer = priceIncludesTax
    ? `*Prices in ${currencyCode} including GST. Sharesight might be tax-deductible, ask your accountant for details`
    : `*Prices in ${currencyCode}. Taxes may apply`;

  return (
    <>
      <Box marginBottom={8}>
        <AccentCardsContainer maxColumns={4}>
          {planOptions.map(plan => {
            const annualPrice = getAnnualPrice(localizedPlans[plan], pricingDiscountPercentage);
            const footer =
              pricingDiscountPercentage && pricingDiscountPercentage > 25 && annualPrice
                ? `${pricingDiscountPercentage}% discount`
                : undefined;

            return (
              <AccentCard
                key={plan}
                color={localizedPlans[plan].color}
                title={plan}
                subTitle={
                  <PriceDetail
                    price={localizedPlans[plan].price}
                    annualPrice={annualPrice}
                    currencySymbol={currencySymbol}
                    currencyCode={currencyCode}
                  />
                }
                footer={footer}
                button={
                  <StyledSignupLink
                    asButton
                    appearance={localizedPlans[plan].buttonAppearance}
                    showArrowIcon
                  >
                    {localizedPlans[plan].buttonText}
                  </StyledSignupLink>
                }
                details={
                  <Features
                    portfolios={localizedPlans[plan].portfolios}
                    customGroups={localizedPlans[plan].customGroups}
                    holdings={localizedPlans[plan].holdings}
                    reportsText={localizedPlans[plan].reportsText}
                    supportLevel={localizedPlans[plan].supportLevel}
                  />
                }
              />
            );
          })}
        </AccentCardsContainer>
      </Box>

      <FooterNote>{taxDisclaimer}. All paid plans include a 7 day free trial.</FooterNote>
    </>
  );
};
