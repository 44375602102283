import { Box } from 'components/Box';
import {
  PriceContainer,
  StyledPrice,
  SmallPrice,
  PriceNote,
  Subtitle,
} from './PricingDetail.styled';

import type { PlanData } from 'page-data/pricing/types';
import type { FC } from 'react';

interface PriceDetailProps extends Pick<PlanData, 'price' | 'annualPrice'> {
  currencySymbol?: string;
  currencyCode?: string;
}

const Price: FC<{ currencySymbol: string; annualPrice: number }> = ({
  currencySymbol,
  annualPrice,
}) => {
  // the monthly price if billed annually
  const monthlyPrice = (annualPrice / 12).toFixed(2);

  const pricingParts = monthlyPrice.split('.');

  const integerPart = pricingParts[0];
  const decimalPart = Number(pricingParts[1]) ? `.${pricingParts[1]}` : null;

  return (
    <StyledPrice>
      {currencySymbol}
      {integerPart}

      {/** rendered even if decimalPart doesn't exist to prevent uneven layout for multiple cards */}
      <SmallPrice>{decimalPart}</SmallPrice>
    </StyledPrice>
  );
};

export const PriceDetail: FC<PriceDetailProps> = ({
  price,
  currencySymbol = '$',
  currencyCode = 'USD',
  annualPrice,
}) => {
  const isFree = price === 0;

  return (
    <>
      <Box marginBottom={1}>
        <PriceContainer>
          <Price currencySymbol={currencySymbol} annualPrice={annualPrice} />
          {annualPrice > 0 && (
            <div>
              <PriceNote>
                {`${currencyCode} per month`}
                <br />
                billed annually
              </PriceNote>
            </div>
          )}
        </PriceContainer>
      </Box>

      <Subtitle>
        {isFree ? (
          'Forever'
        ) : (
          <>
            {currencySymbol}
            {price} {currencyCode} billed monthly
          </>
        )}
      </Subtitle>
    </>
  );
};
