/*
 * The top half of this container is navyBlue,
 * to give the appearance of the PricingCards overlaying the HeroContainer in the pricing page
 */

import styled, { useTheme } from 'styled-components';
import { useMediaQuery } from 'styled-breakpoints/use-media-query';
import { Container } from 'components/Container';
import type { ReactNode } from 'react';

const StyledContainer = styled(Container)`
  background: ${props =>
    `linear-gradient(${props.theme.colors.navyBlue} 40%, transparent 40%) no-repeat`};

  ${({ theme }) => theme.breakpoints.down('lg')} {
    background: transparent;
  }
`;

export const PricingOverlayContainer = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  /** This is to ensure on tablet and smaller screen sizes (when the overlay doesn't show) there is enough padding */
  const isTablet = !!useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <StyledContainer padding={isTablet ? 15 : 1} bottomPadding={15}>
      {children}
    </StyledContainer>
  );
};
