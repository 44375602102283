import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getDimension('size4')} 0;
  gap: ${getDimension('size6')};
`;

export const Feature = styled.div`
  font-size: ${props => props.theme.fontSize.sm};
  line-height: ${props => props.theme.lineHeight.xxs};
`;
