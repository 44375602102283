import type { PlanData } from 'page-data/pricing';

export const getAnnualPrice = (
  plan: PlanData,
  /** The annual discount based on the monthly price; by default, annual discounts are `monthlyPrice * 12 * .75` */
  annualDiscount: number = 25
) => {
  if (annualDiscount > 50 || annualDiscount < 25) {
    throw new Error(`${annualDiscount}% is not a valid discount option!`);
  }

  const discountedPrice = plan.price * 12 * ((100 - annualDiscount) / 100);

  /** annual discounted price rounded to 2 decimal places */
  return Math.round(discountedPrice * 100) / 100;
};
